import { ErrorIcon } from "@hexocean/braintrust-ui-components/Icons";

export const WarningModalIcon = () => {
  return (
    <ErrorIcon
      sx={{
        color: "var(--negative-1)",
        fontSize: "30px",
        verticalAlign: "text-bottom",
      }}
    />
  );
};
