import type { ReactNode } from "react";
import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import {
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useAccountType, useIsNodeStaff } from "@js/apps/common/hooks";
import {
  useGetEmployerOfferHistoryQuery,
  useGetFreelancerOfferHistoryQuery,
} from "@js/apps/jobs/apps/offers/api";
import type { TalentOrEmployerOffer } from "@js/apps/jobs/apps/offers/components";
import { ContractHistoryTable } from "@js/apps/jobs/apps/offers/components/contracts-history-table";
import { useContractHistoryTableFilters } from "@js/apps/jobs/apps/offers/components/contracts-history-table/hooks/use-contract-history-table-filters";

import styles from "./style.module.scss";

type OfferDetailsUpdateHistoryTableProps = {
  offer: TalentOrEmployerOffer;
};

export const OfferDetailsUpdateHistoryTable = ({
  offer,
}: OfferDetailsUpdateHistoryTableProps) => {
  const { isEmployer, isFreelancer } = useAccountType();
  const isNodeStaff = useIsNodeStaff();

  if (isEmployer || isNodeStaff) {
    return <EmployerOfferDetailsUpdateHistoryTable offer={offer} />;
  }

  if (isFreelancer) {
    return <FreelancerOfferDetailsUpdateHistoryTable offer={offer} />;
  }

  return null;
};

const FreelancerOfferDetailsUpdateHistoryTable = ({
  offer,
}: OfferDetailsUpdateHistoryTableProps) => {
  const { updateRowsTotalHeight } = useScrollHistoryTableToTop();
  const { filters } = useContractHistoryTableFilters();
  const { data, isLoading } = useGetFreelancerOfferHistoryQuery({
    id: offer.id,
    filters,
  });

  // If there is only one item, it's just the initial offer
  if (!data || data.count < 2) {
    return null;
  }

  return (
    <ExpandableWrapper>
      <Box mt={3}>
        <ContractHistoryTable
          data={data}
          loading={isLoading}
          className={styles.table}
          getTableContentHeight={updateRowsTotalHeight}
        />
      </Box>
    </ExpandableWrapper>
  );
};

const EmployerOfferDetailsUpdateHistoryTable = ({
  offer,
}: OfferDetailsUpdateHistoryTableProps) => {
  const { updateRowsTotalHeight } = useScrollHistoryTableToTop();
  const { filters } = useContractHistoryTableFilters();

  const { data, isLoading } = useGetEmployerOfferHistoryQuery({
    id: offer.id,
    filters,
  });

  // If there is only one item, it's just the initial offer
  if (!data || data.count < 2) {
    return null;
  }

  return (
    <ExpandableWrapper>
      <Box mt={3}>
        <ContractHistoryTable
          data={data}
          loading={isLoading}
          className={styles.table}
          getTableContentHeight={updateRowsTotalHeight}
        />
      </Box>
    </ExpandableWrapper>
  );
};

const ExpandableWrapper = ({ children }: { children: ReactNode }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box>
      <ExpandTableButton
        expanded={expanded}
        onExpand={() => setExpanded((p) => !p)}
      />
      {expanded && children}
    </Box>
  );
};

const ExpandTableButton = ({
  expanded,
  onExpand,
}: {
  expanded: boolean;
  onExpand: () => void;
}) => {
  return (
    <Box marginTop="24px">
      <Button
        id="expandContractHistory"
        variant="transparent"
        onClick={onExpand}
        sx={{
          padding: "0 !important",
        }}
      >
        <Typography
          variant="paragraph"
          size="medium"
          component="span"
          sx={{
            textDecoration: "underline",
            display: "flex",
            alignItems: "center",
          }}
        >
          {expanded ? (
            <>
              Hide contract history
              <KeyboardArrowUpIcon />
            </>
          ) : (
            <>
              Show contract history
              <KeyboardArrowDownIcon />
            </>
          )}
        </Typography>
      </Button>
    </Box>
  );
};

const useScrollHistoryTableToTop = () => {
  const isMobile = useMediaQuery("md");
  const { filters } = useContractHistoryTableFilters();
  const [rowsTotalHeight, setRowsTotalHeight] = useState(0);

  useEffect(() => {
    window.requestAnimationFrame(() => {
      const elementToScrollTo = document.getElementById(
        "expandContractHistory",
      );

      if (elementToScrollTo) {
        const offset = isMobile ? 200 : 94;

        const scrollHeight =
          elementToScrollTo.getBoundingClientRect().top +
          window.scrollY -
          offset;

        window.scrollTo({ top: scrollHeight, behavior: "smooth" });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, rowsTotalHeight]);

  const updateRowsTotalHeight = (state: {
    rowsMeta: { currentPageTotalHeight: number };
  }) => {
    const newRowsTotalHeight = state.rowsMeta.currentPageTotalHeight;

    if (rowsTotalHeight === newRowsTotalHeight) return;

    setRowsTotalHeight(newRowsTotalHeight);
  };

  return { updateRowsTotalHeight };
};
