import {
  CERTIFICATE_ADDED,
  CERTIFICATE_DELETED,
  CERTIFICATE_EDITED,
  CLICK_AVAILABILITY_TOGGLE,
  CLICK_CHECK_AVAILABILITY_OPTION,
  CLICK_SET_AVAILABILITY,
  CLICK_UPDATE_AVAILABILITY_MENU,
  CLICK_UPDATE_AVAILABILITY_PROFILE,
  FREELANCER_PROFILE_CLICKED,
  FREELANCER_PROFILE_VIEWED,
  SCHOOL_ADDED,
  SCHOOL_DELETED,
  SCHOOL_EDITED,
  SKILLS_SAVED,
  WORK_EXPERIENCE_ADDED,
  WORK_EXPERIENCE_DELETED,
  WORK_EXPERIENCE_EDITED,
  WORK_SAMPLE_ADDED,
  WORK_SAMPLE_DELETED,
  WORK_SAMPLE_UPDATED,
} from "@js/apps/freelancer/action-types";
import type { TrackableUserAction } from "@js/middlewares/analytics/types";
import { Events, EventsProperties } from "@js/services/analytics";
import { logEvent } from "@js/services/analytics/event-logging";
import type { Skill } from "@js/types/admin";

export const handleFreelancerProfileAnalyticsActions = (
  action: TrackableUserAction,
) => {
  switch (action.type) {
    case FREELANCER_PROFILE_VIEWED: {
      logEvent(Events.PROFILE_VIEWED);
      break;
    }

    case FREELANCER_PROFILE_CLICKED: {
      logEvent(Events.PROFILE_CLICK);
      break;
    }

    case SKILLS_SAVED: {
      const skills = action.payload as Skill[];
      const selectedSkillsNames = skills.map((skill) => skill.name);

      logEvent(Events.SELECT_SKILL, {
        [EventsProperties.skill]: selectedSkillsNames,
        [EventsProperties.skills_count]: selectedSkillsNames.length,
      });

      const superpowers = skills.filter((skill) => skill.is_superpower);

      if (superpowers.length) {
        logEvent(Events.SELECT_SUPERPOWERS, {
          [EventsProperties.superpower_skills]: superpowers.map(
            (skill) => skill.name,
          ),
          [EventsProperties.superpower_skills_count]: superpowers.length,
        });
      }

      logEvent(Events.EDIT_PROFILE, {
        [EventsProperties.section_edited]: "skills",
      });
      break;
    }

    case WORK_EXPERIENCE_ADDED: {
      logEvent(Events.ADD_WORK_HISTORY);
      logEvent(Events.EDIT_PROFILE, {
        [EventsProperties.section_edited]: "experience",
      });
      break;
    }

    case CERTIFICATE_ADDED: {
      logEvent(Events.ADD_CERTIFICATES);
      logEvent(Events.EDIT_PROFILE, {
        [EventsProperties.section_edited]: "experience",
      });
      break;
    }

    case SCHOOL_ADDED: {
      logEvent(Events.ADD_EDUCATION);
      logEvent(Events.EDIT_PROFILE, {
        [EventsProperties.section_edited]: "experience",
      });
      break;
    }

    case WORK_SAMPLE_ADDED: {
      logEvent(Events.ADD_PROJECT);
      logEvent(Events.EDIT_PROFILE, {
        [EventsProperties.section_edited]: "projects",
      });
      break;
    }

    case WORK_EXPERIENCE_EDITED:
    case WORK_EXPERIENCE_DELETED:
    case CERTIFICATE_EDITED:
    case CERTIFICATE_DELETED:
    case SCHOOL_EDITED:
    case SCHOOL_DELETED: {
      logEvent(Events.EDIT_PROFILE, {
        [EventsProperties.section_edited]: "experience",
      });
      break;
    }

    case WORK_SAMPLE_DELETED:
    case WORK_SAMPLE_UPDATED: {
      logEvent(Events.EDIT_PROFILE, {
        [EventsProperties.section_edited]: "projects",
      });
      break;
    }

    case CLICK_AVAILABILITY_TOGGLE: {
      if (action.payload) {
        logEvent(Events.CLICK_AVAILABLE_TOGGLE_ON);
      } else {
        logEvent(Events.CLICK_AVAILABLE_TOGGLE_OFF);
      }
      break;
    }

    case CLICK_CHECK_AVAILABILITY_OPTION: {
      switch (action.payload) {
        case ENUMS.FreelancerAvailabilityForWork.DIRECT_HIRE:
          logEvent(Events.CLICK_DIRECT_HIRE_CHECK);
          break;
        case ENUMS.FreelancerAvailabilityForWork.PART_TIME:
          logEvent(Events.CLICK_PART_TIME_CHECK);
          break;
        case ENUMS.FreelancerAvailabilityForWork.FULL_TIME:
          logEvent(Events.CLICK_FULL_TIME_CHECK);
          break;
        default:
          break;
      }
      break;
    }

    case CLICK_SET_AVAILABILITY: {
      logEvent(Events.CLICK_SET_AVAILABILITY);
      break;
    }

    case CLICK_UPDATE_AVAILABILITY_MENU: {
      logEvent(Events.CLICK_UPDATE_AVAILABILITY_MENU);
      break;
    }

    case CLICK_UPDATE_AVAILABILITY_PROFILE: {
      logEvent(Events.CLICK_UPDATE_AVAILABILITY_PROFILE);
      break;
    }
    default: {
      break;
    }
  }
};
