import type { FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";

import { TextField } from "@hexocean/braintrust-ui-components";
import { CertificatesCreatableCombobox } from "@js/components/autocomplete-new/creatable/certificates";

export type RhfCertificatesFieldProps<T extends FieldValues> =
  UseControllerProps<T> & {
    id: string;
    label: string;
    placeholder?: string;
    required?: boolean;
    multiple?: boolean;
    ["aria-label"]?: string;
  };

export const RhfCertificatesField = <T extends FieldValues>({
  name,
  control,
  label,
  placeholder,
  required,
  multiple,
  ["aria-label"]: ariaLabel,
  ...props
}: RhfCertificatesFieldProps<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  return (
    <CertificatesCreatableCombobox
      {...props}
      {...field}
      ref={undefined}
      value={field.value || ""}
      onChange={(_ev, data) => {
        if (!data) {
          return;
        }
        field.onChange(data.value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            "aria-label": ariaLabel,
            ...params.inputProps,
          }}
          label={label}
          required={required}
          error={!!error}
          helperText={error?.message}
          placeholder={placeholder}
        />
      )}
    />
  );
};
