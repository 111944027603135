import React from "react";

import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { WarningModalIcon } from "@js/apps/common/components/warning-modal-icon";
import { RouterLink } from "@js/components/link";
import { Modal } from "@js/components/modal";

import style from "./style.module.scss";

class WithdrawalMethodWarning extends React.Component {
  static Instance = Modal("withdrawal-method-warning", {
    className: style.generalWarning,
    closeButton: false,
  });

  render() {
    return (
      <WithdrawalMethodWarning.Instance>
        <Typography
          component="h1"
          variant="title"
          fontWeight={400}
          size="small"
          className="mb+"
        >
          <WarningModalIcon /> No Banking Information
        </Typography>
        <Typography component="p">
          Attention: before you can be paid, you must set default banking
          information.
        </Typography>
        <div className="buttons right mt++">
          <Button
            variant="transparent"
            color="secondary"
            onClick={WithdrawalMethodWarning.Instance.close}
          >
            Cancel
          </Button>
          <Button
            variant="transparent"
            color="secondary"
            onClick={WithdrawalMethodWarning.Instance.close}
            href="/settings/payments/"
            RouterLink={RouterLink}
          >
            Setup Now
          </Button>
        </div>
      </WithdrawalMethodWarning.Instance>
    );
  }
}

export default WithdrawalMethodWarning;
