import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { WarningModalIcon } from "@js/apps/common/components/warning-modal-icon";
import { useUser } from "@js/apps/common/hooks/use-user";
import { RouterLink } from "@js/components/link";
import { ModalInstance } from "@js/components/modal";

import styles from "./styles.module.scss";

export const TaxFormRequiredWarningContent = () => {
  const user = useUser();
  const isW9FormRequired = user?.is_w9_form_required;

  return (
    <Box>
      <Typography
        component="h1"
        variant="title"
        fontWeight={400}
        size="small"
        className="mb+"
      >
        <WarningModalIcon /> {isW9FormRequired ? "W-9" : "W-8"} Form Required.
      </Typography>
      <Typography component="p">
        {isW9FormRequired
          ? "Before withdrawing funds, all U.S. residents must provide their W-9 tax information."
          : "Before withdrawing funds, all residents outside of U.S. must provide their W-8 tax information."}
      </Typography>
      <div className="buttons right mt++">
        <Button
          variant="transparent"
          color="secondary"
          onClick={ModalInstance.close}
        >
          Cancel
        </Button>
        <Button
          variant="transparent"
          color="secondary"
          onClick={ModalInstance.close}
          href="/settings/payments/"
          RouterLink={RouterLink}
        >
          Setup Now
        </Button>
      </div>
    </Box>
  );
};

export const openTaxFormRequiredWarningModal = () => {
  ModalInstance.open({
    children: <TaxFormRequiredWarningContent />,
    closeButton: false,
    className: styles.modal,
  });
};
