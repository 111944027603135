import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";

import { Loader } from "@hexocean/braintrust-ui-components";
import { updateVisitsCounter } from "@js/apps/common/actions";
import { UniversalSearchContextProvider } from "@js/apps/universal-search/context/universal-search-context";
import { Snackbar } from "@js/components/snackbar";
import { UNEXPECTED_NETWORK_ERROR_MESSAGE } from "@js/constants";
import { useIdParam } from "@js/hooks/use-id-param";
import { AppLayout } from "@js/layouts/app";

import { useGetFreelancerPublicProfileQuery } from "../api";
import { FreelancerProfileMeta } from "../components/freelancer-profile-meta";

import { FreelancerProfileLazy } from "./lazy";

import styles from "./styles.module.scss";

export const FreelancerProfilePage = (): JSX.Element => {
  const freelancerId = useIdParam();
  const {
    currentData: profile,
    isError,
    isLoading,
  } = useGetFreelancerPublicProfileQuery(freelancerId ?? skipToken);

  useEffect(() => {
    if (!freelancerId) {
      return;
    }

    if (isError) {
      Snackbar.error(UNEXPECTED_NETWORK_ERROR_MESSAGE);
      return;
    }

    updateVisitsCounter(freelancerId, ENUMS.VisitsCounterType.TALENT);
  }, [freelancerId, isError]);

  if (!freelancerId) {
    return <Navigate to="/page-not-found/?reloaded=true" replace />;
  }

  return (
    <UniversalSearchContextProvider initialTab="PEOPLE">
      <AppLayout
        coreClassName={styles.profileLayout}
        className="pt0"
        bgcolor="var(--soft-violet)"
        setBgOnBody
        flexColumn
      >
        {isLoading && <Loader centered />}
        {!isLoading && !!profile && <FreelancerProfileLazy profile={profile} />}
      </AppLayout>
      {!!profile && <FreelancerProfileMeta profile={profile} />}
    </UniversalSearchContextProvider>
  );
};
